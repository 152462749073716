import type { ReactNode } from "react";

import { LoadingHintText } from "@components/layout/loading-indicator/loading-hint-text";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { currentDepotState } from "@states/current.depot.state";
import { SP, SSection } from "@styled-components/STags";
import theme from "@styles/theme";
import { parseNumberToCurrencyString } from "smavesto.core/lib/utils/format/parseNumberToCurrencyString";
import PopoverInfo from "@src/components/form/popover-info/PopoverInfo";
import moment from "moment";

export function Balance({ children }: { children: ReactNode }) {
  const [state, currentDepot] = useAsyncSelector(currentDepotState);

  return (
    <SSection>
      {children}
      {currentDepot && (
        <SP
          fontWeight={700}
          fontSize={(currentDepot?.balance || 0) < 1000000 ? "42px" : "36px"}
          color={theme.primary}
          style={{ lineBreak: "anywhere" }}
        >
          {state === "loading" ? (
            <LoadingHintText />
          ) : currentDepot.balance !== undefined ? (
            <>
              {parseNumberToCurrencyString(currentDepot.balance, 2)}
              {currentDepot.balanceDate && (
                <PopoverInfo
                  title="Aktuelles Guthaben"
                  text={`Stand vom ${moment(
                    new Date(currentDepot.balanceDate),
                    "DD.MM.YYYY"
                  ).format("DD.MM.YYYY")}`}
                />
              )}
            </>
          ) : (
            <span
              style={{
                fontSize: 22,
                lineHeight: 1.2,
                display: "block",
                whiteSpace: "pre-wrap"
              }}
            >
              Ihr Guthaben kann aktuell
              <br />
              nicht berechnet werden.
            </span>
          )}
        </SP>
      )}
    </SSection>
  );
}
