import styled from "styled-components";

export const SAllocationList = styled.ul`
  margin: 0;
  padding: 0;
  font-size: 14px;
  list-style-type: none;

  .desc {
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
  ol {
    margin: 0;
    padding-left: 46px;
    font-size: 12px;
  }
`;
