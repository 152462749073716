import styled from "styled-components";

export const STransactions = styled.section`
  display: flex;
  flex-direction: column;
  margin: 30px auto;

  button {
    width: 60%;
    min-width: 204px;
    margin: 8px;
    margin-left: 0;
  }

  @media (max-width: 1200px) {
    flex-direction: row;
    flex-wrap: wrap;

    button {
      width: 25%;
      margin-right: 16px;
    }
  }
`;
