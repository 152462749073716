export const RULES = {
  receivePreContractualInfo: [
    {
      required: true,
      transform: (value: boolean) => value || undefined,
      type: "boolean" as const,
      message:
        "Bitte stimmen Sie dem Versenden der vorvertraglichen Informationen zu."
    }
  ]
};

export const CHECKBOX_LABEL = `Ich möchte die vorvertraglichen Informationen per E-Mail zugeschickt bekommen. *`;

export const CONFIRM_CHECKBOX_LABEL_SECOND_GUARDIAN = `Wir möchten die vorvertraglichen Informationen per E-Mail zugeschickt bekommen. *`;

export const CHECKBOX_LABEL_FIRST_GUARDIAN = `Ich bestätige, dass die Angaben zu unserem Kind korrekt und wahrheitsgemäß sind. *`;

export const CHECKBOX_LABEL_FIRST_GUARDIAN_SOLE_CUSTODY = `Ich bestätige, dass die Angaben zu meinem Kind korrekt und wahrheitsgemäß sind. *`;

export const CHECKBOX_LABEL_SECOND_GUARDIAN = `Ich (gesetzlicher Vertreter 2) bestätige, dass die Angaben zu unserem Kind korrekt und wahrheitsgemäß sind. *`;

export const ValueMap = {
  financialPosition: {
    strategy: {
      NO_RISK: "Altersvorsorge",
      MEDIUM_RISK: "Allgemeine Vermögensbildung und -optimierung",
      HIGH_RISK: "Überproportionale Teilnahme an Kursveränderungen"
    },
    investmentPeriod: {
      SHORT_TERM: "Kurzfristig bis 3 Jahre",
      MEDIUM_TERM: "Mittelfristig 3 bis 5 Jahre",
      LONG_TERM: "Langfristig ab 5 Jahre"
    },
    lossBearingCapacity: {
      NO_LOSS:
        "Ich kann keine bzw. nur geringe Verluste des eingesetzten Kapitals tragen",
      SOME_LOSS:
        "Ich kann Verluste tragen (bis zum vollständigen Verlust des eingesetzten Kapitals)"
    },
    monthlyIncome: null,
    liquidCapital: null
  },
  profile: {
    gender: { MALE: "Herr", FEMALE: "Frau" },
    maritalStatus: {
      LEDIG: "Ledig",
      VERHEIRATET: "Verheiratet",
      LEBENSPARTNERSCHAFT: "Eingetragene Lebenspartnerschaft",
      GESCHIEDEN: "Geschieden",
      VERWITWET: "Verwitwet"
    },
    jobStatus: {
      ANGESTELLTER: "Angestellte/r",
      ANGESTELLTER_OEFFENTLICHER_DIENST: "Angestellte/r im öffentl. Dienst",
      ARBEITNEHMER: "Arbeitnehmer/in",
      ARBEITSLOS: "Arbeitslos",
      BEAMTER: "Beamtin/Beamter",
      HAUSFRAU: "Hausfrau/Hausmann",
      IM_RUHESTAND: "Im Ruhestand",
      SCHUELER: "Schüler/in, Student/in, Auszubildende/r",
      SELBSTSTAENDIGER: "Selbstständige/r",
      SONSTIGES: "Sonstiges"
    },
    jobBranch: {
      BERGBAU: "Bergbau und Gewinnung von Steinen und Erden",
      EINZELHANDEL: "Einzelhandel (ohne Handel mit Kfz)",
      ENERGIEVERSORGUNG: "Energieversorgung",
      SONSTIGE_SPORT_WELLNESS:
        "Erbringung von sonstigen Dienstleistungen – Dienstleistungen des Sports, der Unterhaltung und der Erholung",
      WIRTSCHAFT:
        "Erbringung von wirtschaftlichen Dienstleistungen für Unternehmen & Privatpersonen",
      ERZIEHUNG: "Erziehung u. Unterricht",
      GASTRONOMIE: "Gastronomie",
      GESUNDHEIT: "Gesundheitswesen",
      GRUND_WOHNUNG: "Grundstücks- u. Wohnungswesen",
      GLASWAREN: "Herst. v. Glas und Glaswaren, Verarbeitung von Steinen",
      HERSTELLUNG_KFZ: "Herstellung von Kraftfahrzeugen u. Kraftfahrzeugteilen",
      HOCHBAU: "Hochbau, Bau von Gebäuden",
      IT_SERVICE: "Informationsdienstleistungen",
      SERVICE_HANDEL_KFZ:
        "Instandhaltung/Reparatur/Handel mit Kraftfahrzeugen u. Service",
      KUNST_UNTERHALTUNG:
        "Kreative u. künstlerische u. unterhaltende Tätigkeiten",
      SERVICE_VERKEHR:
        "Lagerei sowie Erbringung von Dienstleistungen für Verkehr",
      LAND_FORST_JAGD: "Landwirtschaft, Forstwirtschaft, Jagd",
      OEFFENTLICH: "Öffentliche Verwaltung",
      SONSTIGE_WISSENSCHAFT:
        "Sonstige freiberufliche/wissenschaftliche Tätigkeiten",
      WASSER_ABFALL_UMWELT:
        "Wasserversorgung, Abwasser- und Abfallentsorgung u. Beseitigung von Umweltverschmutzungen"
    },
    additionalIncome: {
      NICHT_SELBSTAENDIGE_TAETIGKEIT: "Nichtselbstständige Tätigkeit",
      SELBSTAENDIGE_TAETIGKEIT: "Selbstständige Tätigkeit / Gewerbebetrieb",
      VERMIETUNG_VERPACHTUNG: "Vermietung und Verpachtung",
      KAPITALVERMOEGEN: "Kapitalvermögen",
      SONSTIGES: "Sonstiges"
    }
  },
  knowledge: {
    education: {
      HAUPTSCHULE: "Hauptschule",
      BERUFSAUSBILDUNG: "Berufsausbildung",
      MITTLERE_REIFE: "Mittlere Reife",
      ABITUR: "Abitur",
      HOCHSCHULABSCHLUSS: "Hochschulabschluss",
      SONSTIGES: "Sonstiges"
    }
  }
};

export const FOREIGN_CURRENCY = {
  label: "Fremdwährungen",
  info: "Für eine optimale Zusammenstellung Ihrer Portfolios berücksichtigt Smavesto nicht nur verschiedene Anlageklassen, sondern auch unterschiedliche Währungen. Eine Investition in eine Fremdwährung birgt für Sie die Chance, von Kursveränderungen beim Währungstausch zu profitieren, aber auch das Risiko, ggf. Verluste zu verzeichnen."
};

export const EMERGING_MARKETS = {
  label: "in Emerging Markets (Schwellenländer) investieren",
  info: "Bei Emerging Markets handelt es sich um sogenannte Schwellenländer, die sich erst auf dem Weg zu einer modernen Volkswirtschaft befinden. Diese Länder oder Regionen verfügen bisher noch nicht über einen voll entwickelten Markt – aus diesem Grund findet sich hier häufig ein hohes Wirtschaftswachstum, von dem Sie profitieren können. Als Beispiele gelten etwa die Volksrepublik China oder Indien, aber auch kleinere Staaten Europas wie Bulgarien.\nEine Investition in Emerging Markets birgt für Sie die Chance, von der aufstrebenden Wirtschaft dieser Staaten zu profitieren. Es bestehen jedoch auch höhere Risiken (u.a. Währungsrisiken, politische Risiken und ggf. eine eingeschränkte Markttransparenz), die zu Verlusten des eingesetzten Kapitals führen können."
};
