import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { marktkommentarFromSmavestoDe } from "@src/state/cms.state";
import Link from "next/link";
import { useRouter } from "next/router";

const LatestMarktkommentarPreviewSnip: React.FunctionComponent = () => {
  const [, marktkommentar] = useAsyncSelector(marktkommentarFromSmavestoDe(""));

  const [latest] = marktkommentar || [undefined];

  const router = useRouter();

  if (latest)
    return (
      <div
        style={{
          height: 160,
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          textAlign: "center",
          cursor: "pointer"
        }}
      >
        <Link
          style={{ border: 0, height: 100, padding: 0 }}
          href="/dashboard/blog"
        >
          <p style={{ fontWeight: "bold", color: "inhereit" }}>
            {latest.title
              ? latest.title?.replace("Marktkommentar", "").trim()
              : "Marktkommentar"}
          </p>
          <div>
            <img
              alt=""
              src={latest.previewImage || ""}
              width={200}
              style={{ maxWidth: "100%" }}
            />
          </div>
          <div>
            <Link href="/dashboard/blog">Zum Marktkommentar</Link>
          </div>
        </Link>
      </div>
    );

  return null;
};

export default LatestMarktkommentarPreviewSnip;
