import { useContext, useState } from "react";

import { Skeleton } from "antd";
import Link from "next/link";
import { parseNumberToCurrencyString } from "smavesto.core/lib/utils/format/parseNumberToCurrencyString";
import PopoverInfo from "@components/form/popover-info/PopoverInfo";
import { depotDetailsState } from "@states/depot.state";
import { ThemeContext } from "@src/pages/_app";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import SSwitch from "@styled-components/form/SSwitch";
import { SP, SSection } from "@styled-components/STags";

export function CurrentPerformanceView() {
  const [state, depotDetails] = useAsyncSelector(depotDetailsState);

  const [unitPerformance, setUnitPerformance] = useState<"€" | "%">("€");

  const { theme } = useContext(ThemeContext);

  return (
    <div style={{ textAlign: "right" }}>
      <SSection display="flex" alignItems="center" justifyContent="flex-end">
        <SP
          fontWeight={700}
          style={{ color: theme.palette.primary }}
          width="120px"
          margin="0 8px 0 0"
        >
          Performance
          <PopoverInfo
            title="Performance"
            text={
              <>
                <p>
                  In dieser Darstellung behalten Sie die Entwicklung Ihres
                  Portfolios im Blick: Der Graph zeigt alle Veränderungen Ihrer
                  Anlage im gewählten Zeitraum an - nach Guthaben in Euro oder
                  als Wachstumskurve in Prozent. Hierbei handelt es sich um eine
                  zeitgewichtete Performance. Mehr Infos zu der
                  Performanceberechnung erhalten Sie{" "}
                  <Link href="/dashboard/support?segment=performance">
                    hier
                  </Link>
                  .
                </p>
                <p>
                  Die gezeigte Performance ist der Wert nach Kosten und vor
                  Steuern d.h. aufgrund der Steuern weicht die Summe aus dem
                  eingezahlten Guthaben und der Performance von dem aktuellen
                  Guthaben ab.
                </p>
              </>
            }
          />
        </SP>
        {depotDetails && (
          <SP
            data-testid="current-performance"
            fontWeight={700}
            fontSize="22px"
            color={theme.palette.primary}
            width="auto"
          >
            {state === "loading" ? (
              <Skeleton.Button active />
            ) : unitPerformance === "€" ? (
              parseNumberToCurrencyString(depotDetails?.wertzuwachs, 2)
            ) : (
              `${(depotDetails?.currentPerformance ?? 0).toFixed(2)} %`
            )}
          </SP>
        )}
      </SSection>

      <SSwitch
        style={{
          background: theme.palette.primary,
          color: theme.palette.primaryTextColor
        }}
        checkedChildren="€"
        unCheckedChildren="%"
        checked={unitPerformance === "€"}
        onChange={checked => setUnitPerformance(checked ? "€" : "%")}
      />
    </div>
  );
}
