import { Line } from "@ant-design/plots";
import type { Datum } from "@antv/g2plot";
import moment from "moment";

import { useContext } from "react";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { ThemeContext } from "@pages/_app";
import { SP } from "@styled-components/STags";
import { depotDetailsState } from "@states/depot.state";

export const PerformanceChart = () => {
  const [state, depotDetails] = useAsyncSelector(depotDetailsState);

  const loading = state === "loading";

  const performanceData = depotDetails?.performance.map(t => ({
    year: moment(t[0]).format("DD.MM.YYYY"),
    value: t[1]
  }));

  const { theme } = useContext(ThemeContext);

  return performanceData?.length || loading ? (
    <Line
      data={performanceData ?? []}
      height={570}
      xField="year"
      yField="value"
      padding={[12, 30, 88, 50]}
      lineStyle={{
        stroke: theme.palette.primary,
        cursor: "pointer",
        lineWidth: 3
      }}
      loading={loading}
      xAxis={{
        tickCount: 5,
        line: { style: { lineWidth: 5 } },
        label: { style: { fontSize: 14 } }
      }}
      yAxis={{
        label: {
          formatter: (text: string) => `${text}%`,
          style: { fontSize: 14 }
        }
      }}
      tooltip={{
        formatter: (datum: Datum) => ({
          name: `Performance am ${datum.year}`,
          value: `${datum.value.toFixed(2)}%`
        }),
        showTitle: false
      }}
      slider={{
        height: 40,
        textStyle: { fill: "#fff", fontSize: 14 },
        handlerStyle: { height: 40, fill: theme.palette.primary }
      }}
    />
  ) : (
    <SP fontWeight={700} padding="60px" textAlign="center">
      Keine Performance-Daten vorhanden
    </SP>
  );
};
