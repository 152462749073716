import {
  DownloadOutlined,
  RetweetOutlined,
  UploadOutlined
} from "@ant-design/icons";

import CancellationTooltip from "@components/form/tooltips/CancellationTooltip";
import TestModeTooltip from "@components/form/tooltips/TestModeTooltip";

import NextReallocationDepotHint from "@components/layout/hints/NextReallocationDepotHint";
import useIsMobile from "@src/theme/hook/useIsMobile";
import { SButton } from "@styled-components/form/SButtons";
import useThemedModal from "@theme/hook/useThemedModal";
import { onModalFormOpen } from "../../Profile/helpers";
import { ModalFormPayment } from "./ModalFormPayment";
import { ModalFormPayout } from "./ModalFormPayout";
import { ModalFormSavingsPlan } from "./ModalFormSavingsPlan";

export const TransactionButtons: React.FunctionComponent = () => {
  const { contextHolder, openModal } = useThemedModal();

  const isMobile = useIsMobile();

  return (
    <>
      <div style={{ width: "100%" }}>
        <CancellationTooltip>
          <TestModeTooltip>
            <SButton
              style={{ width: isMobile ? "100%" : 240 }}
              type="primary"
              shape="round"
              size="large"
              icon={<DownloadOutlined />}
              onClick={onModalFormOpen(openModal, ModalFormPayment, 600, {
                source: "dashboard"
              })}
            >
              Geld einzahlen
            </SButton>
          </TestModeTooltip>
        </CancellationTooltip>
        <CancellationTooltip>
          <TestModeTooltip>
            <SButton
              style={{ width: isMobile ? "100%" : 240 }}
              type="primary"
              shape="round"
              size="large"
              icon={<UploadOutlined />}
              onClick={onModalFormOpen(openModal, ModalFormPayout, 600, {
                source: "dashboard"
              })}
            >
              Geld auszahlen
            </SButton>
          </TestModeTooltip>
        </CancellationTooltip>
        <CancellationTooltip>
          <TestModeTooltip>
            <SButton
              style={{ width: isMobile ? "100%" : 240 }}
              type="primary"
              shape="round"
              size="large"
              icon={<RetweetOutlined />}
              onClick={onModalFormOpen(openModal, ModalFormSavingsPlan, 600, {
                source: "dashboard"
              })}
            >
              Sparbetrag ändern
            </SButton>
          </TestModeTooltip>
        </CancellationTooltip>
      </div>

      <div style={{ maxWidth: 350 }}>
        <NextReallocationDepotHint />
      </div>
      {contextHolder}
    </>
  );
};
