import { Radio } from "antd";
import { useSetRecoilState } from "recoil";

import CancellationTooltip from "@components/form/tooltips/CancellationTooltip";
import { rangeDepotIDState } from "@states/depot.state";
import { SRadioGroup } from "@styled-components/SRadioGroup";

import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { DepotTimeRanges } from "../TimeRanges";

export function PerformanceRangeSelect() {
  const [, rangeDepotId] = useAsyncSelector(rangeDepotIDState);

  const setRangeDepotID = useSetRecoilState(rangeDepotIDState);

  return (
    <CancellationTooltip placement="topLeft">
      <SRadioGroup
        defaultValue={rangeDepotId || DepotTimeRanges[0].rangeId}
        buttonStyle="solid"
        onChange={e => setRangeDepotID(e.target.value)}
      >
        {DepotTimeRanges.map(range => (
          <Radio.Button value={range.rangeId} key={range.rangeId}>
            {range.title}
          </Radio.Button>
        ))}
      </SRadioGroup>
    </CancellationTooltip>
  );
}
