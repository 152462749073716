import { Col, Row } from "antd";

import useHintCollection from "@components/layout/hints/useHintCollection";
import PageWrapper from "@components/layout/template/PageWrapper/PageWrapper";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import type { IPageBaseProps } from "@src/interfaces/jsx.interface";
import {
  currentDepotIsAccessibleState,
  currentDepotState
} from "@states/current.depot.state";
import { SDashboardCol } from "@styled-components/SDashboardCol";
import { SDashboardContentWrapper } from "@styled-components/SDashboardContentWrapper";
import { detailedBundledLoadingState } from "@utils/recoil/bundledLoadingState";
import isUnderageDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";

import useRateAppModal from "@src/hooks/dashboard/useRateAppModal";
import { Allocation } from "./allocation";
import { BalanceInfo } from "./balance";
import { InvestmentSettings } from "./investment-settings";
import Performance from "./performance/Performance";
import DashboardSkeleton from "./skeleton/DashboardSkeleton";

interface DepotPageProps extends IPageBaseProps {}

const DepotPage: React.FunctionComponent<DepotPageProps> = ({ style }) => {
  const [currentDepotIsAccessibleLoadingState, currentDepotIsAccessible] =
    useAsyncSelector(currentDepotIsAccessibleState);
  const [currentDepotLoadingState, currentDepot] =
    useAsyncSelector(currentDepotState);

  const isUnderage = currentDepot ? isUnderageDepot(currentDepot) : false;

  const loadingState = detailedBundledLoadingState([
    currentDepotIsAccessibleLoadingState,
    currentDepotLoadingState
  ]);

  const [, , hintsCenter, hideDepotDashboardCol] = useHintCollection();

  const { ratingContextHolder } = useRateAppModal();

  if (loadingState.state === "loading") return <DashboardSkeleton />;

  return (
    <PageWrapper title="Depot" style={style}>
      <SDashboardContentWrapper justify="space-between">
        <Col span={24}>{hintsCenter}</Col>

        {currentDepotIsAccessible === true && (
          <>
            {!hideDepotDashboardCol && (
              <SDashboardCol span={24} margin="0 0 60px">
                <Row>
                  <Col xl={6} lg={24}>
                    <BalanceInfo />
                  </Col>
                  <Col xl={18} lg={24}>
                    <Performance />
                  </Col>
                </Row>
              </SDashboardCol>
            )}

            <SDashboardCol md={24} lg={9} margin="0 0 60px">
              <Allocation isUnderage={isUnderage} />
            </SDashboardCol>

            <SDashboardCol md={24} lg={14} margin="0 0 60px">
              <InvestmentSettings isUnderage={isUnderage} />
            </SDashboardCol>
          </>
        )}
      </SDashboardContentWrapper>
      {ratingContextHolder}
    </PageWrapper>
  );
};

export default DepotPage;
