import { selectorFamily } from "recoil";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import type NewsDto from "smavesto.core/lib/types/dto/cms/NewsDto";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import moment from "moment";

export const newsFromSmavestoDeState = selectorFamily({
  key: "newsFromSmavestoDeState",
  get:
    () =>
    async ({ get }) => {
      const res =
        await smavestoCore.services.contentManagement.getNewsFromSmavestoDE();

      return (successOrUndefined<NewsDto[]>(res) || []).sort((a, b) =>
        moment(a.date).isAfter(moment(b.date)) ? -1 : 1
      );
    }
});

export const marktkommentarFromSmavestoDe = selectorFamily({
  key: "marktkommentarFromSmavestoDe",
  get:
    () =>
    async ({ get }) => {
      const news = get(newsFromSmavestoDeState(0));
      return (successOrUndefined<NewsDto[]>(news) || [])
        .filter(a =>
          a.categories.some(b => b.toLowerCase().trim() === "marktkommentar")
        )
        .sort((a, b) => (moment(a.date).isAfter(moment(b.date)) ? -1 : 1));
    }
});
