import { Col, Row } from "antd";

import { CurrentPerformanceView } from "./CurrentPerformanceView";
import { PerformanceChart } from "./PerformanceChart";
import { PerformanceRangeSelect } from "./PerformanceRangeSelect";

const Performance: React.FunctionComponent = () => (
  <>
    <Row style={{ marginBottom: 6 }}>
      <Col lg={16} xs={24}>
        <PerformanceRangeSelect />
      </Col>
      <Col lg={8} xs={24}>
        <CurrentPerformanceView />
      </Col>
    </Row>

    <Col>
      <PerformanceChart />
    </Col>
  </>
);

export default Performance;
