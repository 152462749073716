import { CloseOutlined } from "@ant-design/icons";
import { ThemeContext } from "@src/pages/_app";
import { currentDepotState } from "@src/state/current.depot.state";
import { depotDetailsState, rangeDepotIDState } from "@src/state/depot.state";
import { profileState } from "@src/state/profile.state";
import { SButton } from "@src/styled-components/form/SButtons";
import getArrayRandomElement from "@src/utils/getRandomItemFromArray";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { Modal } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { assertNotEmpty } from "smavesto.core";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import isJuniorDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";
import { useAsyncSelector } from "../useAsyncSelector";

const useRateAppModal = () => {
  const { theme, appModeTheme } = useContext(ThemeContext);

  const [rateAppModalOpen, setRateAppModalOpen] = useState<boolean>();

  const [, depot] = useAsyncSelector(currentDepotState);

  const [, profile] = useAsyncSelector(profileState("loggedInUser"));

  const [, depotDetails] = useAsyncSelector(depotDetailsState);

  const [, rangeDepotID] = useAsyncSelector(rangeDepotIDState);

  const depotIsUnderage = depot && isJuniorDepot(depot);

  const onCancelRating = () => {
    assertNotEmpty(depot);

    setRateAppModalOpen(false);

    // Hide for 2 months
    smavestoCore.services.keyValueStore.setValue(
      depot.userUri,
      "dashboard.rating.hideuntil",
      moment(new Date()).add(2, "month").unix().toString()
    );
  };

  const onCloseModal = () => {
    assertNotEmpty(depot);

    setRateAppModalOpen(false);

    // Hide for 24 hours
    smavestoCore.services.keyValueStore.setValue(
      depot.userUri,
      "dashboard.rating.hideuntil",
      moment(new Date()).add(24, "hour").unix().toString()
    );
  };

  const ratingLinks = [
    "https://de.trustpilot.com/evaluate/www.smavesto.de",
    "https://www.roboadvisor-portal.com/smavesto-test"
  ];

  const googleRatingLink =
    "https://www.google.com/maps/place//data=!4m3!3m2!1s0x47b128164447f77f:0x89ed99e929ba5ffb!12e1?source=g.page.m.dd._&laa=lu-desktop-reviews-dialog-review-solicitation";

  const onPerformRating = async () => {
    assertNotEmpty(depot);
    setRateAppModalOpen(false);

    if (
      profile?.email?.endsWith("gmail.com") ||
      profile?.email?.endsWith("googlemail.com")
    )
      window.open(googleRatingLink);
    else window.open(getArrayRandomElement(ratingLinks));

    //  Hide forever
    await smavestoCore.services.keyValueStore.setValue(
      depot.userUri,
      "dashboard.rating.hideuntil",
      "forever"
    );
  };

  useEffect(() => {
    if (
      depot &&
      profile &&
      depotDetails &&
      rangeDepotID === "FROM_BEGIN" &&
      depotDetails.currentPerformance > 5 &&
      depotDetails.wertzuwachs > 0 &&
      !depotIsUnderage
    ) {
      smavestoCore.services.keyValueStore
        .getValueByKey(depot.userUri, "dashboard.rating.hideuntil")
        .then(keyValue => {
          setRateAppModalOpen(
            isApiErrorResponse(keyValue) || keyValue.value === "empty"
              ? true
              : keyValue.value !== "forever" &&
                  (keyValue === undefined ||
                    moment
                      .unix(parseFloat(keyValue.value))
                      .isBefore(moment(new Date())))
          );
        });
    }
  }, [depotDetails, depot, profile]);

  const ratingContextHolder = (
    <Modal
      width={600}
      bodyStyle={{
        backgroundColor: appModeTheme?.modalBackgroundColor,
        color: appModeTheme?.secondLayerTextColor
      }}
      footer={null}
      onCancel={onCloseModal}
      closable
      closeIcon={
        <CloseOutlined
          style={{
            fill: appModeTheme?.secondLayerTextColor,
            color: appModeTheme?.secondLayerTextColor
          }}
        />
      }
      visible={rateAppModalOpen}
      okButtonProps={{
        hidden: true
      }}
      cancelButtonProps={{
        hidden: true
      }}
    >
      <h3
        style={{ color: appModeTheme?.secondLayerTextColor, marginBottom: 20 }}
      >
        Bewertung abgeben
      </h3>
      <p>
        Möchten Sie Ihre bisherigen Erfahrungen mit anderen teilen? Dann freuen
        wir uns über eine ehrliche Bewertung von Ihnen. Selbst, wenn es nur ein
        paar Sätze sind: Sie helfen damit nicht nur anderen Interessenten, ein
        praxisnahes Bild von Smavesto zu bekommen, sondern Sie helfen auch uns,
        unser Produkt stetig zu verbessern.
      </p>
      <div style={{ display: "flex", gap: "1rem", marginTop: 50 }}>
        <SButton
          type="primary"
          onClick={onPerformRating}
          data-testid="close-select-depot-modal"
        >
          Bewertung abgeben
        </SButton>
        <SButton
          className="reset"
          onClick={onCancelRating}
          data-testid="close-select-depot-modal"
        >
          Nein, keine Bewertung abgeben
        </SButton>
      </div>
    </Modal>
  );

  return { ratingContextHolder };
};

export default useRateAppModal;
