import type { PieConfig } from "@ant-design/plots";
import { Pie } from "@ant-design/plots";
import { Col, Row } from "antd";

import { LoadingHintText } from "@components/layout/loading-indicator/loading-hint-text";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { parseRiskNumValueToLabel } from "@page-components/onboarding/FinancialPosition/strategy/helpers";
import { depotAllocationsState, depotDetailsState } from "@states/depot.state";
import { SH2, SP } from "@styled-components/STags";
import formatNumber from "@utils/format/formatNumber";

import PopoverInfo from "@src/components/form/popover-info/PopoverInfo";
import { useFeatureFlag } from "@src/hooks/useFeatureFlag";
import { ThemeContext } from "@src/pages/_app";
import { useContext } from "react";
import LatestMarktkommentarPreviewSnip from "./LatestMarktkommentarPreviewSnip";
import { SAllocationList } from "./styled";

const PipeColors = [
  "#00ADBA",
  "#00A983",
  "#95C11F",
  "#FBE216",
  "#EF7C00",
  "#D60B52"
];

interface AllocationProps {
  isUnderage: boolean;
}

export const Allocation: React.FunctionComponent<AllocationProps> = () => {
  const { theme, appModeTheme } = useContext(ThemeContext);

  const isNewsFeatureFlagged = useFeatureFlag("NEWS");

  const [state, depotDetails] = useAsyncSelector(depotDetailsState);

  const [, depotAllocations] = useAsyncSelector(depotAllocationsState);

  const pieData = depotAllocations
    ? depotAllocations.map(asset => ({
        type: asset.name,
        value: Number(asset.percentage.toFixed(2))
      }))
    : [];

  const loading = state === "loading";

  const pieConfig: PieConfig = {
    color: PipeColors,
    appendPadding: 10,
    data: pieData,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      type: "outer",
      content: ({ percent, type }) =>
        `${type} ${formatNumber(percent * 100, 2)}%`,
      style: {
        fill: appModeTheme?.secondLayerTextColor
      }
    },
    tooltip: {
      formatter: (item: any) => {
        return { name: item.type, value: `${formatNumber(item.value, 2)}%` };
      }
    },
    interactions: [{ type: "pie-legend-active" }, { type: "element-active" }]
  };

  return (
    <div style={{ width: "100%" }}>
      <Row>
        <Col md={12} xs={12} lg={12}>
          <SH2 fontWeight={700} margin="0 0 10px" color={theme.palette.primary}>
            Aktuelle Allokation{" "}
            <PopoverInfo
              title="Aktuelle Allokation"
              text={`Die aktuelle Verteilung des Vermögens in unterschiedliche
              Anlageklassen (z.B. Aktien, Geldmarkt..) unter Berücksichtigung
              der Risikoklasse.`}
            />
          </SH2>
          <SP
            data-testid="risk-label"
            fontWeight={700}
            fontSize="22px"
            style={{ wordWrap: "break-word" }}
            color={theme.palette.primary}
          >
            {loading ? (
              <LoadingHintText />
            ) : (
              parseRiskNumValueToLabel(depotDetails?.anlageRisiko ?? 0)
            )}
          </SP>
          <SP fontWeight={700}>Anlagewunsch</SP>
        </Col>
        <Col md={12} xs={12} lg={12}>
          <div style={{ width: "100%", overflow: "hiden" }}>
            {!isNewsFeatureFlagged && <LatestMarktkommentarPreviewSnip />}
          </div>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col md={24}>
          <Pie {...pieConfig} legend={false} />
        </Col>
        <Col>
          <SAllocationList>
            {depotAllocations?.map((item, i) => (
              <li key={Math.random()}>
                <div className="desc">
                  <span
                    style={{
                      background:
                        i >= PipeColors.length
                          ? PipeColors[i - PipeColors.length]
                          : PipeColors[i]
                    }}
                  />
                  <b>
                    {item.name} {formatNumber(item.percentage, 2)}%
                  </b>
                </div>
                <ul>
                  {item.assets.map(asset => (
                    <li key={asset.name}>
                      {asset.name} (WKN: {asset.wkn}){" "}
                      {formatNumber(asset.percentage, 2)}%
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </SAllocationList>
        </Col>
      </Row>
    </div>
  );
};
