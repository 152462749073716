import type { ReactNode } from "react";
import { useState } from "react";

import { LoadingHintText } from "@components/layout/loading-indicator/loading-hint-text";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { depotDetailsState } from "@states/depot.state";
import { SP, SSection } from "@styled-components/STags";
import theme from "@styles/theme";
import { parseNumberToCurrencyString } from "smavesto.core/lib/utils/format/parseNumberToCurrencyString";

export function Invested({ children }: { children: ReactNode }) {
  const [state, depotDetails] = useAsyncSelector(depotDetailsState);

  const [investedKey] = useState<"brutto" | "netto">("netto");

  return (
    <SSection margin="20px 0 0px 0">
      {children}

      {depotDetails && (
        <SP
          fontWeight={700}
          fontSize="26px"
          color={theme.primary}
          margin="0 0 6px"
        >
          {state === "loading" ? (
            <LoadingHintText />
          ) : (
            parseNumberToCurrencyString(depotDetails?.invested[investedKey], 2)
          )}
        </SP>
      )}

      <sub>
        Einbezahlte Beträge abzgl. Auszahlungen
        <br />
        im ausgewählten Zeitraum
      </sub>
      {/*  <Switch
        style={{ background: theme.primary }}
        checkedChildren="Ein und Auszahlungen"
        unCheckedChildren="Nur Einzahlungen"
        checked={investedKey === 'netto'}
        onChange={(checked) => setInvestedKey(checked ? 'netto' : 'brutto')}
      /> */}
    </SSection>
  );
}
