import type NationalityDto from "smavesto.core/lib/types/dto/common/NationalityDto";
import { ValueMap } from "./constants";

export const parseCurrencyValue = (value: number) => {
  if (value < 999) return `${value}`;

  return `${value}`
    .split("")
    .reverse()
    .reduceRight(
      (acc, v, i) => (i % 3 === 0 && i > 2 ? `${acc}${v}.` : `${acc}${v}`),
      ""
    );
};

type ValueMap = typeof ValueMap;

export const mapDBValue = <
  D extends keyof ValueMap,
  K extends keyof ValueMap[D],
  V extends keyof ValueMap[D][K]
>(
  dataName: D,
  valueKey: K,
  value: V
) => ValueMap[dataName][valueKey][value];

export const parseCountryCodeToLabel = <T extends NationalityDto>(
  countries: T[],
  code: string
) => {
  return countries.find(c => c.code === code)?.name ?? "";
};

export const getConfirm = (value?: boolean) =>
  value === undefined
    ? "Es sind bisher keine Informationen hinterlegt."
    : value
    ? "Ja"
    : "Nein";

export const getComma = (flag: boolean) => (flag ? ", " : "");
