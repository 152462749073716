import { Form } from "antd";
import { useCallback, useContext, useState } from "react";

import {
  currentDepotInvestmentSettingState,
  currentDepotUserUriState
} from "@states/current.depot.state";
import { useRefreshInvestmentSettings } from "@states/investment-settings.state";
import useThemedModal from "@theme/hook/useThemedModal";
import { openIndependentInfoModal } from "@theme/modal/openThemedModal";
import { assertNotEmpty } from "smavesto.core/lib/utils/typeguards/assertNotEmpty";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

import useOnboardingLabels from "@hooks/onboarding/labels/useOnboardingLabels";
import { useApiErrorHandler } from "@src/hooks/error-handling/useApiErrorHandler";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { ThemeContext } from "@src/pages/_app";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import type InvestmentSettingsDto from "smavesto.core/lib/types/dto/user/InvestmentSettingsDto";

import { useRouter } from "next/router";
import getHumanReadableErrorMessage from "smavesto.core/lib/engine/error-handling/getHumanReadableError";
import { InvestmentConfirmContent } from "./InvestmentConfirmContent";

export const useInvestmentModalForm = (destroyModal: () => void) => {
  const [form] = Form.useForm();

  const router = useRouter();

  const { contextHolder, openModal } = useThemedModal();

  const labels = useOnboardingLabels("dashboard");

  const [, userUri] = useAsyncSelector(currentDepotUserUriState);

  const [isSubmit, setIsSubmit] = useState(false);

  const { theme } = useContext(ThemeContext);

  const [handleApiError] = useApiErrorHandler();

  const setInvestmentSettingsFields = useCallback(
    (res?: InvestmentSettingsDto) =>
      res &&
      form.setFieldsValue({
        ...res,
        emergingMarkets: !res.noEmergingMarkets,
        foreignCurrency: !res.noForeignCurrency,
        investInCrypto:
          router.query.investInCrypto === "true"
            ? true
            : res.investInCrypto || false,
        investInGold: res.investInGold || false
      }),
    [form]
  );

  useAsyncSelector(
    currentDepotInvestmentSettingState,
    setInvestmentSettingsFields
  );

  const refreshInvestmentSettings =
    useRefreshInvestmentSettings("currentDepotOwner");

  const submit = async (body: InvestmentSettingsDto) => {
    assertNotEmpty(userUri);

    setIsSubmit(true);

    const putInvestmentSettingsResponse =
      await smavestoCore.services.user.updateInvestmentSettings(userUri, body);

    if (isApiErrorResponse(putInvestmentSettingsResponse)) {
      if (
        putInvestmentSettingsResponse.key ===
        "validation.user.investmentsettings.not.matching.riskyield"
      ) {
        const modal = openModal("confirm", {
          title: "Hinweis",
          content: getHumanReadableErrorMessage(
            putInvestmentSettingsResponse.key
          ),
          onOk: () => {
            destroyModal();
            router?.push(
              `/dashboard/depot?open=risk-modal&onSave=${encodeURIComponent(
                `/dashboard/depot/?open=investment-settings&forUser=${userUri}`
              )}`
            );
          },
          onCancel: () => {
            modal.destroy();
          },
          okText: labels.investmentRisk.modalOptions.openRiskLabel,
          cancelText: "Abbrechen"
        });
        setIsSubmit(false);
        return;
      }

      handleApiError(putInvestmentSettingsResponse, "modal", "error");
      setIsSubmit(false);
      return;
    }

    refreshInvestmentSettings();
    destroyModal();

    openIndependentInfoModal(
      labels.investmentSettingsModal.investmentSettingsInfo,
      theme
    );

    router.push({
      pathname: router.pathname
    });

    setIsSubmit(false);
  };

  // Marker #1
  const onFinish = async (values: any) => {
    const body = {
      noEmergingMarkets: !values?.emergingMarkets,
      noForeignCurrency: !values?.foreignCurrency,
      onlySustainableCompanies: values?.onlySustainableCompanies,
      investInCrypto: values?.investInCrypto,
      investInGold: values?.investInGold
    };

    if (body.investInGold || body.investInCrypto) {
      openModal("confirm", {
        ...labels.investmentRisk.modalOptions.cryptoAndGold,
        onOk: () => submit(body),
        onCancel: () =>
          form.setFieldsValue({ investInCrypto: false, investInGold: false })
      });
      return;
    }

    if (body.onlySustainableCompanies) {
      openModal("confirm", {
        ...labels.investmentRisk.modalOptions.onlySustainableCompanies,
        content: <InvestmentConfirmContent />,
        onOk: () => submit(body),
        onCancel: () => form.setFieldsValue({ onlySustainableCompanies: false })
      });
      return;
    }

    submit(body);
  };

  return { form, isSubmit, onFinish, contextHolder };
};
