import type { FC } from "react";
import styled from "styled-components";

import type { PropsWithChildren } from "@src/interfaces/jsx.interface";
import useIsMobile from "@src/theme/hook/useIsMobile";

type Props = {
  label: string | React.ReactNode;
  alignSelectionBottom?: boolean;
  testId?: string;
  mobileLayout?: boolean;
  alignment?: string;
  colWidth?: string;
} & PropsWithChildren;

export const EntriesItem: FC<Props> = ({
  label,
  alignSelectionBottom,
  testId,
  children,
  alignment,
  colWidth,
  mobileLayout
}) => {
  const isMobile = useIsMobile();

  return (
    <SEntriesItem
      alignment={isMobile && mobileLayout ? "left" : alignment}
      colWidth={isMobile && mobileLayout ? "100%" : colWidth}
      flexDirection={isMobile && mobileLayout ? "column" : "row"}
      alignSelectionBottom={alignSelectionBottom}
      data-testid={testId}
    >
      <div className="label">{label}</div>
      <div className="value">{children}</div>
    </SEntriesItem>
  );
};

const SEntriesItem = styled.div<{
  alignSelectionBottom?: boolean;
  alignment?: string;
  colWidth?: string;
  flexDirection?: string;
}>`
  display: flex;
  width: 100%;
  align-items: ${({ alignSelectionBottom }) =>
    alignSelectionBottom ? "end" : "start"};
  font-size: 16px;
  color: inherit;
  margin-bottom: 16px;
  flex-direction: ${({ flexDirection }) => flexDirection || "column"};

  .label {
    width: ${({ colWidth }) => colWidth || "50%"};
    padding-right: 8px;
    text-align: ${({ alignment }) => alignment || "right"};
  }

  .value {
    width: ${({ colWidth }) => colWidth || "50%"};
    font-weight: bold;
    overflow-wrap: anywhere;
    margin-top: auto;
  }
`;
