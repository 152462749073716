import PopoverInfo from "@components/form/popover-info/PopoverInfo";
import useOnboardingLabels from "@src/hooks/onboarding/labels/useOnboardingLabels";
import { SH2, SP, SSection } from "@styled-components/STags";

import { ThemeContext } from "@src/pages/_app";
import { useContext } from "react";
import { TransactionButtons } from "../../Transactions/actions/TransactionButtons";
import { Balance } from "./Balance";
import { Invested } from "./Invested";
import { STransactions } from "./styled";

export function BalanceInfo() {
  const labels = useOnboardingLabels("dashboard");

  const { theme } = useContext(ThemeContext);

  return (
    <SSection>
      <SH2 fontWeight={700} margin="0 0 20px" color={theme.palette.primary}>
        Performance
      </SH2>

      <Balance>
        <SP fontWeight={700}>
          Aktuelles Guthaben
          <PopoverInfo
            title={labels.dashboard.balance.title}
            text={labels.dashboard.balance.info}
          />
        </SP>
      </Balance>

      <Invested>
        <SP fontWeight={700}>
          Eingezahltes Guthaben
          <PopoverInfo
            title="Eingezahltes Guthaben"
            text="Das hier angezeigte Guthaben entspricht dem Wert Ihrer Einzahlung. Netto = Einzahlungen abzüglich Auszahlungen, brutto = nur Einzahlungen."
          />
        </SP>
      </Invested>

      <STransactions>
        <TransactionButtons />
      </STransactions>
    </SSection>
  );
}
