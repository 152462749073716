import { compareSync } from "bcrypt-ts";
import type { NextPageContext } from "next";
import { getCookie } from "@utils/cookies";
import DepotPage from "@page-components/dashboard/Depot/DepotPage";

export default DepotPage;

export const getAdditionalSiteProps = (context: NextPageContext) => ({
  USE_AUTH: process.env.USE_AUTH,
  AUTH_USERNAME: process.env.AUTH_USERNAME,
  AUTH_PASSWORD: process.env.AUTH_PASSWORD,
  TESTUSER_AUTH: context.req?.headers.cookie
    ? compareSync(
        `${process.env.AUTH_USERNAME}:${process.env.AUTH_PASSWORD}`,
        getCookie(
          "testuser.authentification",
          context.req?.headers.cookie.toString()
        )
      )
    : false
});

export const getServerSideProps = async (context: NextPageContext) => {
  return {
    props: {
      layout: "secure",
      name: "Depot",
      ...getAdditionalSiteProps(context)
    }
  };
};
