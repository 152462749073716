import { Col, Row, Skeleton } from "antd";

import { RadioGroup } from "@components/form/inputs/form-fields/RadioGroup";
import PopoverInfo from "@components/form/popover-info/PopoverInfo";
import InfoCollapseBox from "@components/layout/dashboard/InfoCollapseBox";
import { validateInvestmentSettings } from "@page-components/onboarding/Investment/validators";
import useOnboardingLabels from "@src/hooks/onboarding/labels/useOnboardingLabels";
import { SCustomDivider } from "@styled-components/SCustomDivider";
import { SCheckbox, SFormItem } from "@styled-components/form/SFormItems";
import { useContext } from "react";

import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { ThemeContext } from "@src/pages/_app";
import { userUriState } from "@src/state/auth.state";
import { knowledgeState } from "@src/state/knowledge.state";
import formatLinebreaks from "@src/utils/format/formatLinebreaks";
import { useRouter } from "next/router";
import { ModalFormContainer } from "../../Profile/ModalFormContainer";
import { useInvestmentModalForm } from "./useInvestmentModalForm";

interface InvestmentModalFormProps {
  destroyModal: () => void;
}

export const InvestmentModalForm: React.FunctionComponent<
  InvestmentModalFormProps
> = ({ destroyModal }) => {
  const { theme } = useContext(ThemeContext);

  const router = useRouter();

  const { form, onFinish, isSubmit, contextHolder } =
    useInvestmentModalForm(destroyModal);

  const [, userUri] = useAsyncSelector(userUriState("currentDepotOwner"));

  const labels = useOnboardingLabels("dashboard");

  const [, knowledge] = useAsyncSelector(knowledgeState("loggedInUser"));

  if (!knowledge || !userUri)
    return (
      <ModalFormContainer
        title="Anlagepräferenzen"
        submitBtnLabel="Anlagepräferenzen speichern"
        isSubmit={isSubmit}
        form={form}
        onFinish={onFinish}
        onCancel={destroyModal}
      >
        <Skeleton />
      </ModalFormContainer>
    );

  return (
    <ModalFormContainer
      title="Anlagepräferenzen"
      submitBtnLabel="Anlagepräferenzen speichern"
      isSubmit={isSubmit}
      form={form}
      onFinish={onFinish}
      onCancel={() => {
        // clear all query params
        router.push({
          pathname: router.pathname
        });
        destroyModal();
      }}
    >
      <Row justify="space-between">
        <Col md={24} xl={7}>
          <RadioGroup
            direction="vertical"
            name="onlySustainableCompanies"
            testId="sustainable-companies-select"
            label={labels.investmentRisk.text}
            onChange={validateInvestmentSettings(
              form,
              theme,
              labels,
              "dashboard",
              knowledge,
              router,
              userUri
            )}
            initialValue={
              labels.investmentRisk.onlySustainableCompanies[0].value
            }
            items={labels.investmentRisk.onlySustainableCompanies}
          />
        </Col>
        <Col md={24} xl={17}>
          <InfoCollapseBox
            style={{ marginBottom: 38 }}
            title={labels.investmentRisk.onlySustainableCompaniesInfo.title}
            isOpen={false}
          >
            {labels.investmentRisk.onlySustainableCompaniesInfo.text}
            <a
              target="_blank"
              href={labels.investmentRisk.onlySustainableCompaniesInfo.link}
              rel="noreferrer"
            >
              <b> Nachhaltigkeitsrichtlinie.</b>
            </a>
          </InfoCollapseBox>
        </Col>
      </Row>

      <SCustomDivider type="horizontal" style={{ margin: "15px auto 30px" }} />

      <Row justify="space-between">
        <Col md={24} xl={13}>
          <SFormItem
            name="foreignCurrency"
            label="Anlagepräferenzen"
            valuePropName="checked"
            style={{ margin: "5px 0" }}
          >
            <SCheckbox
              onChange={validateInvestmentSettings(
                form,
                theme,
                labels,
                "dashboard",
                knowledge,
                router,
                userUri
              )}
            >
              {labels.investmentRisk.noForeignCurrency.label}
              <PopoverInfo
                title={labels.investmentRisk.noForeignCurrency.label}
                text={labels.investmentRisk.noForeignCurrency.info}
              />
            </SCheckbox>
          </SFormItem>

          <SFormItem
            name="emergingMarkets"
            valuePropName="checked"
            style={{ margin: 0 }}
          >
            <SCheckbox
              onChange={validateInvestmentSettings(
                form,
                theme,
                labels,
                "dashboard",
                knowledge,
                router,
                userUri
              )}
            >
              {labels.investmentRisk.noEmerginMarkets.label}
              <PopoverInfo
                title={labels.investmentRisk.noEmerginMarkets.label}
                text={formatLinebreaks(
                  labels.investmentRisk.noEmerginMarkets.info
                )}
              />
            </SCheckbox>
          </SFormItem>

          <SFormItem
            name="investInGold"
            valuePropName="checked"
            style={{ margin: 0 }}
          >
            <SCheckbox
              onChange={validateInvestmentSettings(
                form,
                theme,
                labels,
                "dashboard",
                knowledge,
                router,
                userUri
              )}
            >
              {labels.investmentRisk.investInGold.label}
              <PopoverInfo
                title={labels.investmentRisk.investInGold.label}
                text={formatLinebreaks(labels.investmentRisk.investInGold.info)}
              />
            </SCheckbox>
          </SFormItem>

          <SFormItem
            name="investInCrypto"
            valuePropName="checked"
            style={{ margin: 0 }}
          >
            <SCheckbox
              onChange={validateInvestmentSettings(
                form,
                theme,
                labels,
                "dashboard",
                knowledge,
                router,
                userUri
              )}
            >
              {labels.investmentRisk.investInCrypto.label}
              <PopoverInfo
                title={labels.investmentRisk.investInCrypto.label}
                text={formatLinebreaks(
                  labels.investmentRisk.investInCrypto.info
                )}
              />
            </SCheckbox>
          </SFormItem>
        </Col>
        <Col md={24} xl={11}>
          <InfoCollapseBox
            style={{ marginBottom: 38 }}
            title="Anlagepräferenzen"
            isOpen={false}
          >
            {labels.investmentRisk.infoAi}
          </InfoCollapseBox>
        </Col>
      </Row>
      {contextHolder}
    </ModalFormContainer>
  );
};
